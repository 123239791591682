<template>
  <main class="kb-main" id="kb-helpdesk">
    <lxp-main-header :show-breadcrumb="true" :show-title="true">
      <template v-slot:action>
        <div class="header-dropdown">
          <div class="dropdown" :class="{ 'is-active' : isCategoryActive }"
               @click="isCategoryActive = !isCategoryActive">
            <button class="dropdown-btn kb-btn kb-btn-outline"><strong class="text">{{ faqClassNm }}</strong><i class="icon-arrow"></i></button>
            <div class="dropdown-target">
              <div class="dropdown-box">
                <ul class="dropdown-option-list">
<!--                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link"><strong class="dropdown-option-text">연수제도</strong></a></li>-->
<!--                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link"><strong class="dropdown-option-text">직무연수</strong></a></li>-->
<!--                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link"><strong class="dropdown-option-text">자기주도학습</strong></a></li>-->
                  <li class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" @click="selectClass('')"><strong class="dropdown-option-text">카테고리</strong></a>
                  </li>
                  <li v-for="(item) in faqClassCdDcd" :key="item.cd" class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" @click="selectClass(item.cd)"><strong class="dropdown-option-text">{{ item.cdNm }}</strong></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>

    <div class="main-content min-component">
      <div class="faq-list-container search-container">
        <div class="list-top">
          <div class="top-column">
            <p class="title text-muted">총 {{ paging.totalCount }}건</p>
          </div>
          <div class="top-column">
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" placeholder="FAQ 검색"
                       v-model="search.faqTitle"
                       @keyup.enter="clickSearch"
                >
                <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="items.length === 0" class="search-result">
          <div class="result-empty">
            <img src="../../../assets/lxp/images/common/img_empty.png">
            <p class="text">검색결과가 없어요</p>
          </div>
        </div>

        <div class="board-list-container">
          <ul class="board-list board-list-bordered">
            <li class="board-list-item" v-for="(item, idx) in items" :key="idx" >
              <!-- TODO:: on click is-active list 일때 어떻게 처리할지 -->
              <div class="kb-collapse" :class="{ 'is-active' : showFaqIdx === idx }">
                <div class="kb-collapse-toggle board-row">
                  <a href="javascript:" @click="clickFaqItem(idx)" class="board-link"></a>
                  <div class="board-column column-index"><span class="text">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</span></div>
                  <div class="board-column column-category"><span class="text">{{item.faqClassNm}}</span></div>
                  <div class="board-column column-title"><span class="title">{{item.faqTitle}}</span><i class="icon-new"></i></div>
                  <div class="board-column column-arrow"><i class="icon-arrow"></i></div>
                </div>
                <div class="kb-collapse-target board-row" style="height: fit-content">
                  <div class="board-column column-index"><span class="text">&nbsp;</span></div>
                  <div class="board-column column-category"><span class="text">답변</span></div>
                  <div class="board-column column-content"><span class="text" v-html="item.faqCn"></span></div>
                </div>
              </div>
            </li>

          </ul>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getCdNm,
  getItems,
  getPaging,
  initPaging, initProgressBar,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat,
} from '@/assets/js/util';
import {ACT_GET_HELP_FAQ_LIST} from '@/store/modules/help/help';
import CommonPaginationFront from '@/components/CommonPaginationFront';

export default {
  name: 'HelpFaq',
  components: {CommonPaginationFront, LxpMainHeader},
  setup() {
    const isCategoryActive = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([{}]);
    const search = reactive({
      faqTitle:'',
      faqClassCdDcd: ''
    });

    const faqClassCdDcd = computed(() => store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2023') === 0));

    const faqClassNm = computed(() => {
      if (search.faqClassCdDcd && search.faqClassCdDcd.length > 0) return getCdNm(store, search.faqClassCdDcd);
      return '카테고리';
    });

    const showFaqIdx = ref(-1);

    const getHelpFaqList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FAQ_LIST}`, {
        faqTitle: search.faqTitle,
        faqClassCdDcd: search.faqClassCdDcd,
        stt: '00',
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          document.querySelectorAll('.kb-collapse').forEach(obj => obj.classList.remove('is-active'));
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getHelpFaqList();

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.faqTitle) query.faqTitle = search.faqTitle;
      if(search.faqClassCdDcd) query.faqClassCdDcd = search.faqClassCdDcd;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const selectClass = (dcd) => {
      if (dcd === '2023001') dcd =  '';
      search.faqClassCdDcd = dcd;
      pagingFunc(1, 10);
    };

    const clickFaqItem = (idx) => {
      if (showFaqIdx.value === idx) {
        showFaqIdx.value = -1;
      } else {
        showFaqIdx.value = idx;
      }
    };

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HelpFaq', paging, search, true);
      getHelpFaqList();
      initProgressBar();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HelpFaq', paging, search, true);
    });

    onMounted(() => {
      getHelpFaqList();
    });

    return {
      isCategoryActive,
      items,
      paging,
      search,

      faqClassCdDcd,
      faqClassNm,
      selectClass,

      showFaqIdx,
      clickFaqItem,

      pagingFunc,
      clickSearch,
      getDateFormat,
    };
  },
};
</script>
